import * as React from "react"
import PropTypes from "prop-types"
import logo from "../images/logo.png"
import { useTranslation, useI18next, Link } from 'gatsby-plugin-react-i18next';
import "./header.css"

export default function Header({ siteTitle }) {
  const { t } = useTranslation();
  const { changeLanguage, language } = useI18next()
  function mobileMenu() {
    document.getElementById('nav').classList.toggle('mobile-nav');
    document.getElementById('mobile-menu').classList.toggle('is-active');
  }
  const isBrowser = () => typeof window !== "undefined"
  function addScrollClass(header) {
    header.classList.add('scrolled');
  }
  function removeScrollClass(header) {
    header.classList.remove('scrolled');
  }
  isBrowser() && window.addEventListener('scroll', function () {
    const scrollPos = window.scrollY;
    const header = document.querySelector('#header');
    if (scrollPos > 10) {
      addScrollClass(header);
    } else {
      removeScrollClass(header);
    }
  });

  return (
    <header className="fixed top-0 z-50 w-full px-16 transition duration-300 md:px-5" id="header">
      <div className="flex items-center justify-between w-full px-1 py-5 mx-auto transition duration-300 header-container md:py-4 md:px-0">
        <Link to="/">
          <img src={logo} alt="" className="transition duration-300 md:max-h-12" />
        </Link>
        <div role={"button"} tabIndex={0} className="hidden md:block menu-toggle justify-self-end" id="mobile-menu" onClick={mobileMenu} onKeyDown={mobileMenu}>
          <span className="h-1 mx-auto my-1 transition duration-300 rounded-md bar w-7 md:block bg-slate-800"></span>
          <span className="h-1 mx-auto my-1 transition duration-300 rounded-md bar w-7 md:block bg-slate-800"></span>
          <span className="h-1 mx-auto my-1 transition duration-300 rounded-md bar w-7 md:block bg-slate-800"></span>
        </div>
        <ul className="flex items-center p-0 m-0 transition duration-300 md:bg-white md:left-0 md:top-20 md:absolute md:w-screen md:h-screen md:flex-col md:-translate-x-full" id="nav">
          <li className="transition duration-300 mb-7">
            <Link to="/" className="px-6 py-2 text-2xl transition duration-300 text-default font-first">
              {t("hAnasayfa")}
            </Link>
          </li>
          <li className="transition duration-300 mb-7">
            <Link
              to="/hakkimizda"
              className="px-6 py-2 text-2xl transition duration-300 text-default font-first"
            >
              {t("hHakkimizda")}
            </Link>
          </li>
          <li className="transition duration-300 mb-7">
            <Link
              to="/projelerimiz"
              className="px-6 py-2 text-2xl transition duration-300 text-default font-first"
            >
              {t("hProjelerimiz")}
            </Link>
          </li>
          <li className="transition duration-300 mb-7">
            <Link
              to="/iletisim"
              className="px-6 py-2 text-2xl text-default font-first sm:px-6"
            >
              {t("hIletisim")}
            </Link>
          </li>
          <li className="transition duration-300 mr-0 md:mr-5 mb-7">
            <button
              onClick={() => changeLanguage(language === "tr" ? "en" : "tr")}
              className="px-6 py-2 pr-0 text-2xl text-default font-first sm:px-6"
            >
              {language === "tr" ? "EN" : "TR"}
            </button>
          </li>
        </ul>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
