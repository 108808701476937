import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import whiteLogo from '../images/logo-white.svg'
import "dayjs/locale/tr"
import "dayjs/locale/en"
import dayjs from "dayjs"
import { useI18next, Link, useTranslation } from "gatsby-plugin-react-i18next"
import postLogo from '../images/post-imza.svg'
import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
         }
       }
     }
   `)

  if (language === "en") {
    dayjs.locale("en")
  } else {
    dayjs.locale("tr")
  }

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          width: '100%',
        }}
      >
        <main>{children}</main>
        <footer className="mt-10 w-full h-80 bg-default rounded-tr-full flex items-center justify-center relative md:h-fit md:py-10 md:rounded-none">
          {/* © {new Date().getFullYear()}, MG Mimarlık */}
          <div className="w-10/12 md:w-11/12">
            <div className="w-9/12 flex items-start justify-between md:flex-col md:items-center md:w-full">
              <img src={whiteLogo} alt="white logo" className="md:mb-5 md:mx-auto md:scale-75" />
              <div className="flex flex-col items-start md:mb-5">
                <Link to="/" className="font-first text-2xl text-white mb-1 text-left w-full md:text-center md:text-lg">{t("hAnasayfa")}</Link>
                <Link to="/hakkimizda" className="font-first text-2xl text-white mb-1 text-left w-full md:text-center md:text-lg">{t("hHakkimizda")}</Link>
              </div>
              <div className="flex flex-col items-start md:mb-5">
                <Link to="/projelerimiz" className="font-first text-2xl text-white mb-2 text-left w-full md:text-center md:text-lg md:mb-1">{t("hProjelerimiz")}</Link>
                <Link to="/projelerimiz/restaurant-kafe" className="font-first text-2xl text-white mb-1 text-left w-full md:text-center md:text-lg">{t("fRestoran")}</Link>
                <Link to="/projelerimiz/penthouse-daire" className="font-first text-2xl text-white mb-1 text-left w-full md:text-center md:text-lg">{t("fPenthouse")}</Link>
                <Link to="/projelerimiz/diger-projeler" className="font-first text-2xl text-white mb-1 text-left w-full md:text-center md:text-lg">{t("fOthers")}</Link>
                <Link to="/projelerimiz/villa" className="font-first text-2xl text-white mb-1 text-left w-full md:text-center md:text-lg">{t("fVillas")}</Link>
              </div>
              <div className="flex flex-col items-start md:mb-5">
                <Link to="/iletisim" className="font-first text-2xl text-white mb-1 text-left w-full md:text-center md:text-lg">{t("hIletisim")}</Link>
                <a href="mailto:mgenanmimarlik@gmail.com" className="font-first text-2xl text-white mb-1 text-left w-full md:text-center md:text-lg">mgenanmimarlik@gmail.com</a>
              </div>
            </div>
          </div>
          <a href="https://postkreatif.com" target={"_blank"} rel="noreferrer" className="flex items-center font-first text-white font-medium text-xs absolute right-20 bottom-5">
            <img src={postLogo} alt="post kreatif logo" className="mr-1" />
            {t("fPostKreatif")}
          </a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout